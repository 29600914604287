html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: black;
}

@keyframes breathe {
  0% {
    font-variation-settings: 'wght' 100;
  }

  50% {
    font-variation-settings: 'wght' 200;
  }
  100% {
    font-variation-settings: 'wght' 100;
  }
}

.txt-container {
  margin-top: -100px;
}

.txt {
  font-size: 16rem;
  color: white;
  $timing: 2500ms;

  &.tic {
    font-weight: 100;
    animation: breathe $timing infinite both;
  }

  &.tac {
    font-weight: 200;
    animation: breathe $timing infinite both;
    animation-delay: $timing/2;
  }
}
